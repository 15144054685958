<template>
  <b-media-body>
    <div class="mail-details">
      <div class="mail-items">
        <h5 class="mb-25">
          {{ ticket.author }} to <strong>{{ ticket.expert_group }}</strong>
          <span class="mail-date ml-50">
            <feather-icon
              icon="DownloadIcon"
              size="12"
            />
            {{ formatDateToMonthShort(ticket.created_at, { hour: 'numeric', minute: 'numeric', }) }}</span>
          <span class="mail-date ml-50">
            <feather-icon
              icon="ActivityIcon"
              size="12"
            />
            {{ formatDateToMonthShort(ticket.updated_at, { hour: 'numeric', minute: 'numeric', }) }}</span>
        </h5>
        <span class="text-truncate">{{ ticket.subject }}</span>
      </div>
      <div class="mail-meta-item">
        <b-badge
          :variant="resolveLabelColor(ticket.status)"
          class="mr-50"
        >
          {{ ticket.status }}
        </b-badge>
      </div>
    </div>

    <div class="mail-message">
      <div class="text-truncate mb-50">
        <feather-icon
          icon="UserIcon"
          size="12"
        /> {{ (ticket.expert_agent !== '') ? ticket.expert_agent : 'Not selected yet.' }}
      </div>
      <div class="text-truncate mb-50">
        {{ filterTags(ticket.message) }}
      </div>
      <div
        v-if="ticket.lastreply"
        class="text-truncate mb-50"
      >
        <small>Last reply :</small> {{ filterTags(ticket.lastreply.author_name) }} at {{ formatDateToMonthShort(ticket.lastreply.created_at, { hour: 'numeric', minute: 'numeric', }) }} &mdash; {{ filterTags(ticket.lastreply.message) }}
      </div>
      <div class="border-info mb-50 p-1 rounded">
        <div class="d-flex justify-content-between align-content-center">
          <div class="mail-items">
            <h6 class="mb-25">
              {{ ticket.ticket_data.author }}
              <small>from {{ (ticket.ticket_data.company_name) ? ticket.ticket_data.company_name : '-' }}</small>
              <span class="mail-date ml-50">
                <feather-icon
                  icon="DownloadIcon"
                  size="12"
                />
                {{ formatDateToMonthShort(ticket.created_at, { hour: 'numeric', minute: 'numeric', }) }}</span>
              <span class="mail-date ml-50">
                <feather-icon
                  icon="ActivityIcon"
                  size="12"
                />
                {{ formatDateToMonthShort(ticket.updated_at, { hour: 'numeric', minute: 'numeric', }) }}</span>
            </h6>
            <span class="text-truncate">{{ ticket.ticket_data.subject }}</span>
          </div>
          <div class="mail-meta-item">
            <b-badge
              variant="light-info"
              class="mr-50"
            >
              {{ ticket.ticket_data.channel }}
            </b-badge>
            <b-badge
              :variant="resolveLabelColor(ticket.ticket_data.status)"
              class="mr-50"
            >
              {{ ticket.ticket_data.status }}
            </b-badge>
            <b-badge
              :variant="resolvePrioColor(ticket.ticket_data.priority)"
              class="mr-50"
            >
              {{ ticket.ticket_data.priority }}
            </b-badge>
          </div>
        </div>
        <p class="pb-0 pt-50 m-0">
          {{ filterTags(ticket.ticket_data.message) }}
        </p>
        <p
          v-if="ticket.ticket_data.attachments && ticket.ticket_data.attachments.length!==0"
          class="pb-0 pt-50 m-0"
        >
          <b-badge
            v-for="attch in ticket.ticket_data.attachments"
            :key="attch.attachment_id"
            variant="light-secondary"
            class="mr-25 mb-25"
          >
            {{ attch.filename }}
          </b-badge>
        </p>
      </div>
    </div>
  </b-media-body>
</template>

<script>
import {
  BMediaBody, BBadge,
} from 'bootstrap-vue'
import { filterTags, formatDateToMonthShort } from '@core/utils/filter'
import useEmail from '../ticket/useEmail'

export default {
  components: {
    BMediaBody, BBadge,
  },
  props: {
    ticket: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { resolveLabelColor, resolvePrioColor } = useEmail()
    return {
      filterTags,
      formatDateToMonthShort,

      // useEmail
      resolveLabelColor,
      resolvePrioColor,
    }
  },
}
</script>
